import React from "react";
import Link from "next/link";
import Head from "next/head";
import Layout from "../common/Layout";

export default function Custom404() {
  return (
    <Layout>
      <Head>
        <title>Modulabs | 404</title>
      </Head>
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <div className="error-inner">
                <h1>404</h1>
                <h2 className="title">Something’s not right.</h2>
                <div className="view-more-button">
                  <Link href="/">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="btn-default">Go Back Home</a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
