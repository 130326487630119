import React, { PropsWithChildren } from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";

function Layout({ children }: PropsWithChildren<unknown>) {
  return (
    <main className="page-wrapper">
      <Header className="header-not-transparent" />
      {children}
      <Footer />
    </main>
  );
}
export default Layout;
